import { Component, OnInit } from '@angular/core';
import { MainService } from '../_services/main.service';
import { MechanicsService } from '../_services/mechanics.service';
import { ViennaSection } from '../../../../server/src/interfaces/ViennaSection.interface';

@Component({
	selector: 'table-sections',
	templateUrl: './table-sections.component.html',
	styleUrls: ['./table-sections.component.css']
})
export class TableSectionsComponent  {

	public sections: ViennaSection[] = []

	constructor(public mas: MainService, public ms: MechanicsService) { }

	filterColumn2($event) {
		this.mas.sectionsFilter = $event.target.value.toLowerCase().trim()
		this.mas.filterSections()
	}


}
