import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutPastillesComponent } from './layout-pastilles/layout-pastilles.component';
import { PageVideosComponent } from './page-videos/page-videos.component';
import { PageFaqComponent } from './page-faq/page-faq.component';

const routes: Routes = [
	{path: '' , component: LayoutPastillesComponent},
	{path: 'videos' , component: PageVideosComponent},
	{path: 'faq' , component: PageFaqComponent},
	{path: '*' , redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
