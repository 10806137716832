import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { MainService } from './_services/main.service';
import { MechanicsService } from './_services/mechanics.service';
// import { ShortcutInput } from 'ng-keyboard-shortcuts';

import { environment } from "../environments/environment"
import { Subscription } from 'rxjs';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	host: {
		'(document:keyup)': 'handleKeyboardEvent($event)'
	}
})
export class AppComponent {


	// to prevent Typescript from complaining at compile time
	public wipoAuth;

	public environment = environment

	// scriptsLoaded: boolean = false


	public helpOptions: any[] = [];
	public helpMore: any[] = [];
	public langChangeSub: Subscription

	public languageOptions: any[] = [];



	constructor(public mas: MainService,
		public ms: MechanicsService,
		public ts: TranslateService,
		private changeDetector: ChangeDetectorRef) {

		this.languageOptions = ms.availableLangs.map(lang => ({ "code": lang }))

		this.ts.onLangChange.subscribe(($event: TranslationChangeEvent) => {
			// const l = `app.component onLangChange subscription - `
			this.buildStuff()
		});

		window["environment"] = environment;
	}

	ngAfterViewInit() {

		const l = `AppComponent ngAfterViewInit() - `


		const intervalRetry = setInterval(() => {
			if (!this.ms.preferences.lang) {
				return;
			}
			this.buildStuff()
			clearInterval(intervalRetry)
		}, 200)


		this.langChangeSub = this.mas.languageChangeObserver.subscribe(($event: CustomEvent) => {
			// this subscription is triggered from the "wipoLanguageChange" event, which is fired by the WIPO navbar on lang change
			// console.log(`${l}Language change subscription triggered - lang = `, lang);
			this.buildStuff()
		})

		/*if (!this.ms.isDevMode)*/  this.loadWipoNavbar()
	}

	ngAfterViewChecked() {
		if (this.ms.isDevMode) {
			this.changeDetector.detectChanges();
		}
	}


	buildStuff() {
		this.buildHelpMenu()
	}

	buildHelpMenu() {

		const l = `appcomponent buildHelpMenu() - `

		// console.log(`${l}Rebuilding help menu`)

		this.helpOptions = [  // Optional, set custom links for Contact and FAQ  (default = WIPO CONTACT)
			{
				"code": "contact", // - code property is mandatory
				"link": `mailto:vienna_classificator@wipo.int` // - link property is optional
			},
			{
				"name": "FAQs",
				"code": "faq",
				"link": environment.appUrl + "/faq",
				"targetTab": "_self"
			},
		];

		this.helpMore = [
			{
				"name": this.ms.translate("how_to_use"),
				"link": environment.appUrl + "/videos",
				"targetTab": "_self"
			},
			{
				"name": this.ms.translate("more_about_vienna"),
				"code": "policy",
				"link": `https://www.wipo.int/classifications/vienna/${this.ms.preferences.lang}/`,
				"targetTab": "_blank"
			},
			{
				"name": this.ms.translate("terms_of_use"),
				"code": "terms",
				"link": `https://www.wipo.int/tools/${this.ms.preferences.lang}/disclaim-ipportal.html`,
				"targetTab": "_blank"
			},
			{
				"name": this.ms.translate("privacy_policy"),
				"code": "policy",
				"link": `https://www.wipo.int/tools/${this.ms.preferences.lang}/privacy_policy-ipportal.html`,
				"targetTab": "_blank"
			},
		];
	}

	loadWipoNavbar(): void {

		const wipoComponentsPath: string = environment.wipoComponentsPath;

		// console.log(`wipoComponentsPath : `, wipoComponentsPath);

		[
			`${wipoComponentsPath}/polyfills/webcomponents-loader.js`,
			`${wipoComponentsPath}/wipo-init/wipo-init.js`,
			`${wipoComponentsPath}/wipo-navbar/wipo-navbar.js`
		].forEach(src => {
			document.head.appendChild(Object.assign(document.createElement('script'), {
				src,
				async: true
			}));
		});
	};

	handleKeyboardEvent(event: KeyboardEvent) {
		const l = `handleKeyboardEvent() - `
		// console.log(`${l}event.key = `, event.key)

		try {


			switch (event.key) {
				case "Escape":
					if (this.ms.currentModal.length) {
						this.ms.closeCurrentModal()
					} else {
						this.ms.back()
					}
					break;


				case "Tab":
					this.mas.selectChecked()
					break;


				default:
				// console.log(`${l}No action bound to '${event.key}'`)

			}
		} catch (err) {
			console.error(`${l}caught error = `, err)
		}
	}

}


