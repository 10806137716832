import { Injectable } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import { ViennaSection } from '../../../../server/src/interfaces/ViennaSection.interface';

import { animalsSeriesMapping } from "../../assets/imports/animalsSeriesMapping";


import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';

/*
	import { allSections } from "./imports/allSections"
	import { allDivisions } from "./imports/allDivisions"
	import { allCategories } from "./imports/allCategories"
*/

const regexes = {
	series: /(s[eé]ries? [IV]{1,4})/gi,
	divisions: / (\d{1,2}\.\d{1,2})(?!\.?\d)/g, // will match "division 3.11." and "divisions 2.1, 2.3, 2.5 or 2.7," but not 1.2.3
	sections: /(\d{1,2}\.\d{1,2}\.\d{1,2})/g
}

@Injectable({
	providedIn: 'root'
})
export class MechanicsService {

	public isLoading: boolean = false
	public isDevMode: boolean = !environment.production

	public assetsPrefix = environment.assetsPrefix

	// Settings (flags true/false), see header's setting menu
	public layout: string = "centered"; // "centered" (image drop, image editor), or "pastilles"
	public preselect: boolean
	public showAuxiliaryOf: boolean
	public showRelevance: boolean
	public showRelevanceType: string // "stars" , "score"
	public slideSpeed: number = 500 // ms

	public display: any = {
		modalOverlay: false,
		modalVideo: false,
		modalPrincipalSections: false,
		slided: false, // pastille layout
		page1: true,  // in pastille layout
		page2: false // in pastille layout
	}

	public currentModal: string = ""
	public currentFileName: string = ""
	public apiOffline: boolean = false

	// SECTIONS AND DIVISIONS
	public allSections: ViennaSection[] = [];
	public allDivisions = []; // Used only to generate tooltips
	public allCategories = [];

	private animalSeriesTooltips: any;

	public selectedCategory: ViennaSection = null;

	// LANGUAGE STUFF
	// public lang --> Moved to preferences.lang
	public availableLangs: string[] = ["en", "fr", "de", "es"];
	public translations: JSON;


	public preferences: any = {
		lang: null, // Will be properly initialized in switchLang() by trying to guess the user's browser language
		preselect: false,
		showAuxiliaryOf: false,
		showRelevance: true,
		showRelevanceType: "stars",
		viennaVersion: "v8" // Nicolas : his Java back-end does not accept 8, so he asked me to send "v8" instead
	};

	constructor(private toastaService: ToastaService,
		private toastaConfig: ToastaConfig,
		public ts: TranslateService) {

		const l = `MS constructor - `

		// PREFERENCES

		/*
			for (let setting of ["preselect", "showAuxiliaryOf"]) {
				this[setting] = !!localStorage.getItem(setting) || false
			}
			for (let setting of ["showRelevance"]) {
				this[setting] = !!localStorage.getItem(setting) || true
			}
			this.showRelevanceType = localStorage.getItem("showRelevanceType") || "stars"
		*/

		const preferencesString = localStorage.getItem("VIE.prefs");


		if (preferencesString) {

			this.preferences = Object.assign(this.preferences, JSON.parse(preferencesString));

			if (Number.isInteger(+this.preferences.viennaVersion)) {
				console.log(`${l}adding "v" to "${this.preferences.viennaVersion}"`)
				this.preferences.viennaVersion = "v" + this.preferences.viennaVersion;
				this.savePreferences();
			}

			if (!this.availableLangs.includes(this.preferences.lang)) {
				this.preferences.lang = this.availableLangs[0]
			}

			// console.log(`${l}Final preferences = `, this.preferences)
		}

		this.toastaConfig.theme = 'material';

		window["allSections"] = this.allSections

		/*
			  CONSTRUCTOR LANGUAGE STUFF
		*/
		// this language will be used as a fallback when a translation isn't found in the current language
		this.ts.setDefaultLang('en');

		// Extracting the full translations object from Angular translate service :) Cool
		// This subscription is triggered from switchLang when doing this.ts.use(lang)
		this.ts.onLangChange.subscribe(($event: TranslationChangeEvent) => {

			const l = `TRANSLATIONSERVICE onLangChange subscription - `

			const lang = $event.lang

			// console.log(`${l}incoming lang='${lang}', this.preferences.lang='${this.preferences.lang}' - Identical? ${lang === this.preferences.lang} - full event = `, $event)

			if (lang !== this.preferences.lang) {
				// For some reason I can't explain, sometimes, the GUI language is EN, but this subscriptiton triggers out of the blue ith a FR translation object.
				// console.log(`${l}Irrelevant language change, discarding. this.preferences.lang='${this.preferences.lang}', received translations object in '${lang}'`)
				// window["translations"] = $event.translations
				this.switchLang(this.preferences.lang); // I must do this, because ms.preferences.lang==='en' but Angular's translation object is in 'de' for some reason (???????) so if this happens, I manually trigger a language change in the current language O_o WTF
				return
			}

			this.translations = $event.translations
			// console.log(`${l}translations object is now = `, this.translations);

			window["translations"] = $event.translations
		});


		this.switchLang(); // initializing language after subscriptions

		this.buildStuff()
	}

	back() { // Used in pastille layout, going from sections back to the categories pastilles. Putting it here so it can be called, for instance, when dropping a new image.

		this.display.page1 = true
		this.display.slided = false

		setTimeout(() => {
			this.display.page2 = false

			// console.log(`back() - setting selectedCategory to null`)
			this.selectedCategory = null
		}, this.slideSpeed)
	}

	async buildStuff() {

		// console.time("Building and hydrating all objects took")

		await this.loadViennaStuff();
		this.buildAnimalSeriesTooltips()
		this.hydrateObjects()

		// console.timeEnd("Building and hydrating all objects took")
	}

	async loadViennaStuff() {

		const l = `ms.loadViennaStuff() - `;

		/*
			Dynamically loading Categories, Divisions and Sections based on vienna version and language
		*/

		// console.log(`${l}Loading vienna ${this.preferences.viennaVersion} stuff in ${this.preferences.lang}`)

		try {


			;[
				this.allCategories,
				this.allDivisions,
				this.allSections
			] = await Promise.all(
				["categories", "divisions", "sections"].map(async (what) => (await fetch(`assets/imports/vienna_${this.preferences.viennaVersion}/${what}-${this.preferences.lang}.json`)).json()
				));

			if (this.allCategories["error"]) {
				throw "Try in English"
			}

		} catch (err) {

			try {

				this.toast({ type: "warning", msg: `Vienna classification ${this.preferences.viennaVersion} is not yet available in language '${this.preferences.lang.toUpperCase()}'. Defaulting to English` })

					;[
						this.allCategories,
						this.allDivisions,
						this.allSections
					] = await Promise.all(
						["categories", "divisions", "sections"].map(async (what) => (await fetch(`assets/imports/vienna_${this.preferences.viennaVersion}/${what}-en.json`)).json()
						));

			} catch (err2) {

				alert(`Failed loading vienna_${this.preferences.viennaVersion}/${this.preferences.lang}.json`)
			}

		}

		// console.log(`${l}allCategories =`, this.allCategories)
		// console.log(`${l}allDivisions =`, this.allDivisions)
		// console.log(`${l}allSections =`, this.allSections)

	}

	buildAnimalSeriesTooltips() {

		const l = `buildAnimalSeriesTooltips() - `

		let series = Object.keys(animalsSeriesMapping)

		// console.log(`${l}this.allSections = `, this.allSections)

		for (let key of series) { // key == "Series IV"

			let output: string = animalsSeriesMapping[key]
				.map(classification => {
					let section = this.allSections.find(s => s.c === classification);
					return `<tr><td>${classification} :</td><td>${section.n}</td><tr>`;
				})
				.join("")

			output = `<table><tbody>${output}</tbody></table>`

			this.animalSeriesTooltips = this.animalSeriesTooltips || {}
			this.animalSeriesTooltips[key] = output
		}

	}

	closeCurrentModal(caller: string = "unknown") {
		if (this.currentModal.length) {
			this.toggleModal(this.currentModal)
		}

		if (this.currentModal === "PrincipalSections") {
			for (let section of this.allSections) { section.checked = false; }
		}
	}

	hydrateObjects() {

		const l = `ms.hydrateObjects() - `

		// for (let lang of this.availableLangs) {

		// Division 28 is special... it has no section, but the API still returns results like 28.03.01 and 28.11.01... So I'm adding it to the sections
		let division28 = this.allDivisions.filter(d => /^28/.test(d.c))
		this.allSections = this.allSections.concat(division28)


		this.allSections = this.allSections.map((section: ViennaSection) => {

			// "rehydrating" the keys names that were shortened to reduce data transfer weight --> BUT WHY??
			// Also generating tooltips

			// console.log(`${l}MAP - section = `, section)

			let output = {
				name: section.n,
				nameLower: "", // for faster filtering
				classification: section.c,
				classificationLevel1: section.c.split(".")[0],
			}

			output["nameLower"] = (output.classification + " " + output.name.toLowerCase())

			if (section.nt) {
				section.nt.forEach(note => {
					if ([
						"Including",
						"Y compris",
					].some(word => note.includes(word))) {
						output["nameLower"] += " " + note
					}
				})
			}


			output["nameLower"] = output["nameLower"].replace(/[\u0300-\u036f]/g, "") // Also removing accents/diacritics

			if ("".normalize) {
				output["nameLower"] = output["nameLower"].normalize('NFD')
			}


			// Testing for "section 1.12.3" in the name and adding a tooltip
			let matches: string[]; // --> [" 1.11"] if one match, [" 1.12", " 1.13"] if two matches (using "g" flag)

			matches = section.n.match(regexes.sections) || [];  // --> [" 1.11"] if one match, [" 1.12", " 1.13"] if two matches (using "g" flag)

			if (matches && matches.length) {

				matches = matches.map(m => m.trim())

				for (let classification of matches) { // classification = "1.12.3"

					let tempSection = this.allSections.find(section => section.c === classification)

					let tooltipContent = `<div>${tempSection.n}</div>`;

					if (tempSection.nt) {
						tooltipContent += "<ul>"
						for (let note of tempSection.nt) tooltipContent += `<li>Note: ${note}</li>`
						tooltipContent += "</ul>"
					}

					tooltipContent = tooltipContent // Total hack to scre up the content of the tooltip so it doesn't get a recursive tooltip
						.split("")
						.map(char => `§${char}`)
						.join("")

					let fulltooltip = `<span class='tooltipWord'><div class='tooltip column'>${tooltipContent}</div>${classification}</span>`

					output["nameForDisplay"] = (output["nameForDisplay"] || section.n).replace(classification, fulltooltip)
				}

				output["nameForDisplay"] = output["nameForDisplay"].replace(/§/g, "") // Removing the hack preventing recursive tooltip
			}


			// Testing for "section 2.6" in the name and adding a tooltip

			matches = section.n.match(regexes.divisions) || [];  // --> [" 1.11"] if one match, [" 1.12", " 1.13"] if two matches (using "g" flag)

			if (matches && matches.length) {

				matches = matches.map(m => m.trim())

				for (let classification of matches) { // classification = "1.12"

					let division = this.allDivisions.find(division => division.c === classification)

					let tooltipContent = `<div>${division.n}</div>`;

					if (division.nt) {
						tooltipContent += "<ul>"
						for (let note of division.nt) tooltipContent += `<li>Note: ${note}</li>`
						tooltipContent += "</ul>"
					}

					tooltipContent = tooltipContent // Total hack to screw up the content of the tooltip so it doesn't get a recursive tooltip
						.split("")
						.map(char => `§${char}`)
						.join("")

					let fulltooltip = `<span class='tooltipWord'><div class='tooltip column'>${tooltipContent}</div>${classification}</span>`

					output["nameForDisplay"] = (output["nameForDisplay"] || section.n).replace(classification, fulltooltip)
				}

				output["nameForDisplay"] = output["nameForDisplay"].replace(/§/g, "") // Removing the hack preventing recursive tooltip
			}


			if (regexes.series.test(section.n)) {
				let match: string = section.n.match(regexes.series)[0] // match = "Series II" or "série IV"
				let romanNumber = match.split(" ")[1]
				let tooltipContent = this.animalSeriesTooltips[romanNumber]
				output["nameForDisplay"] = (output["nameForDisplay"] || section.n).replace(regexes.series, `<span class='tooltipWord'><div class='tooltip column'>${tooltipContent}</div>$1</span>`)
			}

			if (section.nt) output["notes"] = section.nt
			if (section.a) output["auxiliaryOf"] = section.a

			output["category"] = section.c.split(".")[0]

			return output
		})

		this.allDivisions = this.allDivisions.map(division => {
			let output = {
				name: division.n,
				classification: division.c
			}
			if (division.nt) output["notes"] = division.nt
			return output
		})

		this.allCategories = this.allCategories.map(category => {
			let output = {
				name: category.n,
				classification: category.c
			}
			if (category.nt) output["notes"] = category.nt

			return output
		})
		// }
	}

	savePreferences() { // this.preferences is modified directly by [(ngModel)]
		localStorage.setItem("VIE.prefs", JSON.stringify(this.preferences));
	}


	switchLang(lang?: string) { // called without argument in the constructor, to initialize preferences.lang

		const l = `MS switchLang('${lang}') - `

		// console.log(`${l}this.preferences = `, this.preferences)

		if (lang) { // this.preferences.lang is necessarily defined at this point (it was initialized from the constructor before)

			this.preferences.lang = "" + lang;

			let oldLang = "" + this.preferences.lang

			// console.log(`${l}372 Rebuilding all stuff in preferences.lang = '${this.preferences.lang}'`)

			for (let newCategory of this.allCategories) {
				let oldCategory = this.allCategories.find(c => c.classification === newCategory.classification);
				["checked", "suggested", "score", "visited"].forEach(key => newCategory[key] = oldCategory[key]);
			}

			for (let newSection of this.allSections) {
				let oldSection = this.allSections.find(s => s.classification === newSection.classification);
				["checked", "autoChecked", "score", "hidden", "suggested", "selected", "inModal"].forEach(key => newSection[key] = oldSection[key]);
			}
			this.allSections.sort((a, b) => a.score > b.score ? -1 : 1)


			this.selectedCategory =
				this.selectedCategory
				&&
				this.allCategories.find(c => c.classification === this.selectedCategory.classification)

		} else {

			if (!this.preferences.lang) { // Initializing app's language, trying to match the browser's language

				// console.log(`${l}399 No lang found in the localStorage, looking in navigator's language...`);

				lang = window.navigator["userLanguage"] || window.navigator.language
				lang = lang.substring && lang.substring(0, 2).toLowerCase();

				// console.log(`${l}405 Found lang in navigator : '${lang}'`)

				if (!this.availableLangs.includes(lang)) {
					// console.log(`${l}407 Lang '${lang}' is not available, defaulting to "en"`)
					lang = "en";
				}

				this.preferences.lang = lang

				// console.log(`${l}413 ms.preferences.lang is now='${lang}'`)
			}
		}

		this.savePreferences();

		// the lang to use, if the lang isn't available, it will use the current loader to get them.
		// To execute last, because this emits an "TranslationChangeEvent" that triggers the onTranslationChange subscription in the constructor, and refresh the this.translations JSON. It also triggers every other subscriptions in services and components, to recompute custom keys. that's why Moment locale must be changed before
		// console.log(`${l}417 Triggering TS with lang '${lang}'`)
		this.ts.use(lang);

	}

	toast({ type = "info", title = "", msg = "", timeout = 5000 }) {

		const toastOptions: ToastOptions = {
			title,
			msg,
			timeout,
			showClose: true
		};

		this.toastaService[type](toastOptions);
	}

	toggleModal(name: string) { // "Login", "PrincipalSections", without "Modal"

		name = name.replace(/^modal/i, "") // foolproof

		// console.log(`MS : toggling modal ${name}`);
		this.currentModal = name
		let n = `modal${name}`

		this.display[n] = !this.display[n]
		this.display["modalOverlay"] = this.display[n]

		// console.log(`this.display = `, JSON.parse( JSON.stringify(this.display) ) );
		if (!this.display[n]) {
			setTimeout(() => {
				this.currentModal = ""
			}, 400);
		}
	}

	translate(word: string): string {

		const l = `ms.translate() - `

		// console.log(`${l}translating '${word}'`)

		// Normally, in templates, the | translate pipe is used. But in the code, I also need to access the translations object. Since the TranslateService uses complicated (obviously) Observables with subscriptions, I said fuck this s**t, I'll just help myself in my JSON. The JSON is extracted from the TranslateService in the constructor of MS

		// console.log(`this.translations = `, this.translations);

		if (!this.translations) {
			// It's async, the translations object hasn't loaded yet

			// Little hack, trying to simulate language change to load correctly the translations object and trigger onLangChange.subscribe (in the constructor)
			// console.log(`${l}trying to trigger translations reload... Should see "TranslationChangeEvent" next`);

			const langBackup = "" + this.preferences.lang
			const langTartget = this.availableLangs.find(l => l !== this.preferences.lang)
			this.ts.use(langTartget);
			this.ts.use(langBackup);

			// console.log(`${l}loading translation for '${word}'... typeof this.translations=` + typeof this.translations)

			return "..."
		}

		if (!this.translations[word]) {
			return word // `MS -> translations[${level1}] doesn't exist!` --> Returning the original untranslated, because it can be a MongoDB error or something
		}

		const toReturn = this.translations[word]

		// console.log(`${l}returning : ` + toReturn);

		return toReturn
	}

}
