
// ANGULAR CORE
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Translations
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// MODULES
import { AppRoutingModule } from './app-routing.module';

// ENVIRONMENT
import { environment } from 'src/environments/environment';

// COMPONENTS
import { ModalPrincipalSectionsComponent } from './modal-principal-sections/modal-principal-sections.component';
import { LayoutPastillesComponent } from './layout-pastilles/layout-pastilles.component';
import { TableSectionsComponent } from './table-sections/table-sections.component';
import { TableSelectedComponent } from './table-selected/table-selected.component';
import { ButtonsExportComponent } from './buttons-export/buttons-export.component';
import { QuickAddComponent } from './quick-add/quick-add.component';
import { PageVideosComponent } from './page-videos/page-videos.component';
import { ModalVideoComponent } from './modal-video/modal-video.component';
import { PageFaqComponent } from './page-faq/page-faq.component';
import { AppComponent } from './app.component';
import { OptionsComponent } from './options/options.component';
import { ImagedropComponent } from './imagedrop/imagedrop.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';

// PLUGINS
import { ClickOutsideModule } from 'ng-click-outside';
import { AngularDraggableModule } from 'angular2-draggable';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastaModule } from 'ngx-toasta';

// https://github.com/ngx-translate/core
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, environment.assetsPrefix + "/assets/i18n/", ".json");
}

@NgModule({
	declarations: [
		AppComponent,
		OptionsComponent,
		ImagedropComponent,
		ImageEditorComponent,
		ModalPrincipalSectionsComponent,
		LayoutPastillesComponent,
		TableSectionsComponent,
		TableSelectedComponent,
		ButtonsExportComponent,
		QuickAddComponent,
		PageVideosComponent,
		ModalVideoComponent,
		PageFaqComponent
	],
	imports: [
		AngularDraggableModule,
		HttpClientModule,
		BrowserModule,
		FormsModule,
		BrowserAnimationsModule, // required animations module
		DragDropModule,
		KeyboardShortcutsModule.forRoot(),
		ToastaModule.forRoot(),
		ClickOutsideModule,
		ClipboardModule,
		AppRoutingModule,

		// https://github.com/ngx-translate/core
		TranslateModule.forRoot({

			defaultLanguage: window.navigator["userLanguage"] || window.navigator.language, // "en"

			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
	],
	providers: [],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA], // Allows the app to compile despite not knowing what <wipo-navbar> is (those scripts will be created and loaded at runtime)
})
export class AppModule { }
