import { Component, OnInit } from '@angular/core';
import { MainService } from '../_services/main.service';
import { MechanicsService } from '../_services/mechanics.service';
import { ViennaSection } from '../../../../server/src/interfaces/ViennaSection.interface';


@Component({
	selector: 'layout-pastilles',
	templateUrl: './layout-pastilles.component.html',
	styleUrls: ['./layout-pastilles.component.css']
})
export class LayoutPastillesComponent implements OnInit {

	

	constructor(public mas: MainService, public ms: MechanicsService) { }

	ngOnInit() {

	}


	categoryClicked(category:ViennaSection){

		this.ms.display.page2 = true
		this.ms.display.slided = true

		setTimeout(()=>{
			this.ms.display.page1 = false
		}, this.ms.slideSpeed)

		this.mas.checkCategory(category)
	}

}
