import { Component, OnInit } from '@angular/core';
import { MechanicsService } from '../_services/mechanics.service';
import { MainService } from '../_services/main.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ViennaSection } from '../../../../server/src/interfaces/ViennaSection.interface';

@Component({
	selector: 'table-selected',
	templateUrl: './table-selected.component.html',
	styleUrls: ['./table-selected.component.css']
})
export class TableSelectedComponent implements OnInit {

	constructor(public mas: MainService, public ms: MechanicsService) { }

	ngOnInit() {
	}

	onDragEnd($event: CdkDragDrop<string[]>) {

		moveItemInArray(this.mas.selectedSections, $event.previousIndex, $event.currentIndex)

	}

	
}
