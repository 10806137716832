import { Component, OnInit } from '@angular/core';
import { MechanicsService } from '../_services/mechanics.service';

import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';

const iframeSrcs = {
	en: "https://docs.google.com/document/d/e/2PACX-1vRB3xIDLU-otW4vE-FaPNwFeYbEQBdGIB1HEwXtYEo1fst0HHx3AgtprK3ZLGpDGPUDAuy9eitxZgJW/pub?embedded=true",
	fr: "https://docs.google.com/document/d/e/2PACX-1vT2ll4mLuT-d_RkbpO217_aKt2sJ_WBtFc89uPrdbr3RBVz_KYUuMtU5puYpldyqdGTQW7gfhj3FvD5/pub?embedded=true",
    es : "https://docs.google.com/document/d/e/2PACX-1vRp7aDb1D3-1Kkq5ZVjp3SmxIwonHyZBwlGUj3QmU5HaQu8r-oA1M9d9UPC04IE_oTLZ5zHAj2-wuIV/pub?embedded=true",
    de : "https://docs.google.com/document/d/e/2PACX-1vRJQd_Y6rW0oWdDJ81JP-jEcwRqvYpXGbf2by_CbImIRuKQAUQctht6P8vHvMBkWR5zR7YxDVjGz-2j/pub?embedded=true"

}

@Component({
	selector: 'page-faq',
	templateUrl: './page-faq.component.html',
	styleUrls: ['./page-faq.component.css']
})
export class PageFaqComponent implements OnInit {

	public iframeSrc: SafeResourceUrl

	constructor(public ms: MechanicsService,
		public sanitizer: DomSanitizer) {



	}

	ngOnInit() {
		this.getIframeSrc()
		document.addEventListener('wipoLanguageChange', () => this.getIframeSrc())
	}

	getIframeSrc(): SafeHtml {
		return this.sanitizer.bypassSecurityTrustResourceUrl(iframeSrcs[this.ms.preferences.lang])
	}

}
