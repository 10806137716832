import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ViennaSection } from "../../../../server/src/interfaces/ViennaSection.interface";
import { ImageClassificationResponse } from "../../../../server/src/interfaces/ImageClassificationResponse.interface";
import { MechanicsService } from './mechanics.service';

import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

const minScore: number = 0.5; // Percentage of confidence to reach in order to get a star and put the section in blue

import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';



// Use this for dev, locally, when the classification API can't be contacted
// declare var require: any
// const mockResponse = require("./imports/mockResponse.json")

let mapping_version = {
	v8: 'vienna8',
	v9: 'vienna9'
}

@Injectable({
	providedIn: 'root'
})
export class MainService {


	public base64: string = ""

	public editMode: boolean = false // true = displays the image editor instead of the drop zone. Since it's an *ngIf, it also triggers the editor's constructor and init

	public originalBase64: string = "" // to be able to reset, doesn't change

	public res: ImageClassificationResponse // in case of language change, so we don't need to call the API again, we can just use the cached results

	public sectionsFilter: string = "" // free input filter at the top of column2

	public selectedSections: ViennaSection[] = [] // I need an independent array for drag and drop :( I can't use the full allSections and just hide lines...

	public highestScores = {
		category: 0,
		section: 0
	}

	public languageChangeObserver: Observable<any> = fromEvent(document, "wipoLanguageChange").pipe(distinctUntilChanged()); // Converting document event to a public Observable, so I can subscribe/unsubscribe from anywhere

	constructor(public ms: MechanicsService, public http: HttpClient) {

		const l = `MAS constructor - `

		this.languageChangeObserver.subscribe(async ($event) => {

			/*
				In MAS and not in MS, bacause it calls this.sendImageData(), which can't be done from MS
			*/

			const l = `WIPO NAVBAR LANGUAGE CHANGE subscription - `

			const lang = $event.detail && $event.detail.languageSelected;
			// console.log(`${l}WIPO Navbar Language change subscription triggered - incoming lang='${lang}' - FULL $event = `, $event);

			if (lang === this.ms.preferences.lang) {
				// console.log(`%c${l}Language is the same, not switching language`, 'color: #bada55')
				return
			}

			// console.log(`%c${l}Switching language '${this.preferences.lang}' to '${lang}' `, "color:blue")
			this.ms.switchLang(lang) // will subsequently trigger ts.onLangChange, which will refresh the translations object
			this.ms.savePreferences();

			// Need to reload all categories and sections in the new language, to rehydrate all objects, recreate all tooltips etc.
			await this.ms.buildStuff()

			// Need to reclassify the image (all ratings hve been lost when loading the categories in another language)
			await this.sendImageData()

			this.selectedSections = this.selectedSections.map(section => this.ms.allSections.find(s => s.classification === section.classification))

			// this.suggest() --> Redundant, is done after sendImageData() anyway
		})

		const intervalRetry = setInterval(() => {
			if (!this.ms.preferences.lang) {
				return;
			}
			this.resetResults()
			clearInterval(intervalRetry)
		}, 200)


	}

	ngOnChanges(changes) {

		const l = `mas.ngOnChanges() - `

		// console.log(`${l}changes=`, changes)

	}

	buildResults(): void {

		const l = `buildResults() - `

		let categoriesScores = {
			/* "1" : 28,
			"2" : 0.5 */
		}

		for (let index in this.res.classes) {
			let split = this.res.classes[index].split("-"), // "03.07.22-Hummingbirds" --> ["03.07.22" , "Hummingbirds"]
				classification: string = split[0].replace(/0(\d)/g, "$1") // "03.05.01" --> "3.5.1"

			let section = this.ms.allSections.find(s => s.classification === classification)

			if (!section) { // Special case for division 28 which has no sections

				let temp = classification.split(".")
				temp.pop()
				classification = temp.join(".") // Changed "28.5.1" to "28.5"

				console.warn(`buildResults() - Could not find section ${this.res.classes[index]}! Looking for ${classification}...`);

				section = this.ms.allSections.find(s => s.classification === classification)

				if (section) {
					// console.log(`Found :`, section);
				} else {
					console.warn(`Could not find ${classification}... Skipping this result`);
					continue;
				}
			}

			section.score = this.res.weights[index] || 0 // score == 0 means "not in the API results" but can still be displayed when filtering
			section.score *= 100 // looks nicer

			let category = classification.split(".")[0]; // "3.5.1" -> "3"

			categoriesScores[category] = categoriesScores[category] || 0
			categoriesScores[category] += section.score

			section.score = +section.score.toFixed(2)
		}

		this.ms.allSections.sort((a, b) => a.score > b.score ? -1 : 1)

		// Now sorting categories from sections results

		this.highestScores.category = 0

		for (let category of this.ms.allCategories) {

			category.score = categoriesScores[category.classification] || 0;

			if (category.score > 0 && category.score < 1) {
				category.score = +category.score.toFixed(1) // toFixed returns a bloody STRING -_-
			}

			if (category.score >= 1) {
				category.score = Math.floor(category.score + 0.5)
			}

			if (category.score > this.highestScores.category) this.highestScores.category = category.score
		}
	}

	call(route: string, payload = {}): Promise<any> {

		// console.log(`CALL - window.location.host=`,window.location.host);

		// If the API can't be contacted, for development purposes, just uncomment the below line, it will simulate an API call and send back a mock response
		/*
		if(window.location.host.includes("localhost")){
			console.info(`MS call - using mock data instead of real API call`);
			return new Promise( resolve => resolve(mockResponse) )
		}
		*/

		this.ms.isLoading = true

		let prefix = environment.apiPrefix

		

		return lastValueFrom(this.http
			.post(prefix + route, payload))
			.then((res: any) => {
				this.ms.isLoading = false
				if (res.error) {
					console.log(res.error)
					throw res
				}
				return res
			})
			.catch(err => {
				this.ms.isLoading = false
				console.error("CALL error : ", err)
				alert(this.ms.translate("error calling api"))
			})
	}

	checkCategory(category?: ViennaSection): void {

		category = category || this.ms.allCategories[0]

		this.ms.selectedCategory = category

		if (category.checked) return; // radio buttons!

		for (let category of this.ms.allCategories) category.checked = false

		category.checked = true

		category.visited = true

		this.clearFilter()
	}

	checkSection({ classification = null, section = null, showToast = false }) {

		if (!classification && !section) {
			console.error(`mas.checkSection() - no classification and no section passed, can't check anything`)
		}

		// 1. Checks the corresponding section.

		// 2. If this section has one principal, also check this principal

		// 3. If the section has more than one principal
		// if at least one of these principal sections is checked, keep going
		// Else, bring the principal sections modal.

		// 4. Otherwise, just selectChecked()

		section = section || this.ms.allSections.find(s => s.classification === classification)
		classification = classification || section.classification

		// console.info(`Checking ${section.name}`)

		// 1. Checks the corresponding section.
		section.checked = true

		if (section.auxiliaryOf) {

			// 2. If this section has one principal, also check this principal.
			if (section.auxiliaryOf.length === 1) {

				// console.info(`Section has one auxiliary.`)

				let principal: ViennaSection = this.ms.allSections.find(s => s.classification === section.auxiliaryOf[0])

				if (!principal || (!principal.checked && !principal.selected)) {

					// console.info(`Checking principal section  ${principal.name}`)

					if (showToast) {
						principal.autoChecked = true
						let msg = this.ms.translate("is auxiliary of") + ` "${principal.classification} - ${principal.name}". ` + this.ms.translate("checked automatically")
						this.ms.toast({ msg, timeout: 8000 })
					}

					// console.log(`Recursively checking ${principal.checked}`)

					this.checkSection({ classification: section.auxiliaryOf[0] }) // recursive.
				} else {
					// console.info(`This section has one principal, it's already checked or selected, all good.`)
				}

			} else { // 3. We have more than one principal


				// Looking if at least one of these sections is already checked or selected
				let allSelectedAndChecked: string[] = this.ms.allSections
					.filter(s => s.checked || s.selected)
					.map(s => s.classification)

				if (!allSelectedAndChecked.some(classification => section.auxiliaryOf.includes(classification))) { // None of the auxiliaries is checked or selected.
					// console.info(`This section has many principals, none of them is checked or selected. Bringing up the principals modal.`)

					for (let s of this.ms.allSections) {
						s.inModal = section.auxiliaryOf.includes(s.classification)
					}

					let bestPrincipal = this.ms.allSections.find(s => s.inModal) // The first result will necessarily the one with the best score, because all sections are sorted by score.

					// console.log(`Auto-checking the best principal in modal : `, bestPrincipal)

					this.checkSection({ section: bestPrincipal }) // recursive. to save some time

					return this.ms.toggleModal("PrincipalSections")
				} else {
					// console.info(`This section has many principals, at least one of them is already checked or selected, all good`)
				}
			}
		}

		// console.info(`Selecting checked sections...`)

		this.selectChecked()

	}

	clearFilter() {
		this.sectionsFilter = ''
		this.filterSections()
	}

	computeStars(score: number): number {
		if (score > 45) return 5
		if (score > 25) return 4
		if (score > 10) return 3
		if (score > 5) return 2
		if (score >= minScore) return 1
		return 0
	}

	filterSections() {

		if (!this.ms.selectedCategory) {
			// console.log(`filterSections() - no selected category, gracefully exiting.`)
			return
		}

		if (this.sectionsFilter.length === 1) return; // Typed only one character in the filter, not enough to trigger filtering

		let filterWithoutDiaritics = this.sectionsFilter.toLowerCase()
			.replace(/[\u0300-\u036f]/g, "") // removing diacritics

		if (!!"".normalize) {
			filterWithoutDiaritics = filterWithoutDiaritics.normalize('NFD')
		}

		for (let section of this.ms.allSections) {

			if (section.category !== this.ms.selectedCategory.classification) {
				section.hidden = true
				continue
			}

			section.hidden = !section.nameLower.includes(filterWithoutDiaritics)
		}
	}

	onlySections({ properties = [], inverse = false, booleanOnly = false }): ViennaSection[] {

		// Utility that returns a partial sections array (for the principal sections modal, for instance)

		// Example : onlySections({ properties: ['selected'] }) returns only sections with selected===true
		// Example : onlySections({ properties: ['selected','inModal'] }) returns only sections with selected===true and inModal===true

		// Note : at first, I tried to return (boolean | ViennaSection[]) but when I used it in a Pug template as onlySections("selected").length I was getting compilation errors : "boolean | ViennaSection[] does not have property 'length'". So I tweaked this function so it returns only arrays -_-

		if (booleanOnly) { // boolean, quick
			let section = this.ms.allSections.find(s => properties.every(property => s[property]))
			return section ? [section] : []
		}

		let filtered: ViennaSection[] = this.ms.allSections
			.filter(s => inverse ?
				!properties.every(property => s[property]) :
				properties.every(property => s[property]))

		// console.log(`onlySections() - arguments = `, {properties, inverse, booleanOnly}, "Returning : ", filtered)

		return filtered // full array, slow
	}

	preselect() {

		// console.log(`preselect() - Auto checking suggested sections`)

		for (let section of this.ms.allSections) {

			if (!section.suggested) continue;

			if (this.selectedSections.find(s => s.classification === section.classification)) continue;

			this.selectedSections.push(section) // I want to push them to an independent array for drag-drop sorting
			section.selected = true

			// console.log(`preselect() - selecting ${section.name}`)

			// If it is an auxiliary, automatically selecting the best principal section

			if (section.auxiliaryOf) {

				let principals: ViennaSection[] = this.ms.allSections.filter(s => section.auxiliaryOf.includes(s.classification))

				principals.sort((a, b) => a.score > b.score ? -1 : 1)

				let bestPrincipal = principals.pop()

				if (!this.selectedSections.find(s => s.classification === bestPrincipal.classification)) {
					// console.log(`Auto - selecting principal section : ${bestPrincipal.name}`)
					this.selectedSections.push(bestPrincipal)
					bestPrincipal.selected = true
				}
			}
		}

	}

	resetResults(clearSelection = true) {

		const l = `mas.resetResults - `

		this.sectionsFilter = ""

		if (this.ms.layout === "pastilles" && this.ms.selectedCategory) {
			this.ms.back() // only useful in the case of pastille layout on page2 (sections).
		}

		// console.log(`${l}this.ms.preferences.lang = `, this.ms.preferences.lang)

		this.ms.allCategories = this.ms.allCategories.map(category => {
			["checked", "suggested", "visited"].forEach(key => delete category[key])
			category.score = 0
			category.stars = []
			return category
		})

		let toReset = ["checked", "hidden", "inModal", "autochecked", "suggested", "visited"]

		if (clearSelection) {
			toReset.push("selected")
			this.selectedSections = []
		}

		this.ms.allSections = this.ms.allSections.map(section => {
			toReset.forEach(key => delete section[key])
			section.score = 0
			section.stars = []
			return section
		})

	}


	selectChecked() {

		for (let section of this.ms.allSections) {
			if (section.checked) {
				// console.log(`pushing ${section.name} to selected sections`)
				this.selectedSections.push(section) // I want to push them to an independent array for drag-drop sorting
				section.checked = false
				section.selected = true
			}
		}

		// this.ms.closeCurrentModal() // just in case it was open

		this.filterSections()

		window.dispatchEvent(new Event("added to selection"))
	}


	async sendImageData(clearSelection = true) { // Called from imagedrop.component

		if (!this.base64.length) return

		// console.log(`calling image API`)

		this.resetResults(clearSelection)

		let url = '/image-classification/'+ (mapping_version[this.ms.preferences.viennaVersion] || 'vienna8')
		let res: ImageClassificationResponse = await this.call(url, {
				imageBase64: this.base64,
			}) 

		this.ms.layout = "pastilles"

		if (!res) { // Probably fell in the .catch(

			// Even if the classification API can't be reached, we must still be able to manually classify. The app behaves slightly differently if the API is offline.
			this.ms.apiOffline = true

			this.ms.allSections.sort((a, b) => a.classification > b.classification ? -1 : 1)

			return
		}

		this.ms.apiOffline = false
		// console.log(`got image API response`)

		this.res = res // in case of language change, so we don't need to call the API again, we can just use the cached results

		// console.log(`SendImageData() res = `, res)

		setTimeout(() => {
			this.buildResults()
			this.suggest()

			if (this.ms.preselect) { // auto select suggested sections
				this.preselect()
			}
		})

	}

	suggest() {

		let suggestedCount: number = 0;

		// console.log(`Suggesting sections...`)

		for (let section of this.ms.allSections) {

			if (section.score >= 10 || suggestedCount < 3) { // We want : 3 minimum, no maximum as long as score >= 10
				section.suggested = true
				suggestedCount++
			}
			section.stars = Array(this.computeStars(section.score)) // generating an array of length 5 so it is iterable to draw stars
		}

		// console.log(`Suggesting categories...`)

		// We want : 1 minimum, 8 maximum, score >= 0.1
		// In pastille mode, categories are not sorted by score but by classification. The first three are not the most relevant. If I want the first 8 best scored, I need to work a bit

		suggestedCount = 0
		let categoriesScores = this.ms.allCategories.map(c => ({ classification: c.classification, score: c.score }))

		categoriesScores.sort((a, b) => a.score > b.score ? -1 : 1) // which I can't do directly on the categories themselves (not to be sorted by score)

		// console.log(`categoriesScores (sorted by score) = `, categoriesScores)

		for (let categoriesScore of categoriesScores) {

			if (categoriesScore.score >= minScore || suggestedCount === 0) {
				let toSuggest = this.ms.allCategories.find(c => c.classification === categoriesScore.classification)
				toSuggest.stars = Array(this.computeStars(toSuggest.score)).fill(0) // generating an array of length 1 to 5 so it is iterable to draw stars
				toSuggest.suggested = true
				// console.log(`Suggesting category ${toSuggest.name} - score = ${toSuggest.score} (should equal ${categoriesScore.score})- suggestedCount = ${suggestedCount}`)
				suggestedCount++
			}
			if (suggestedCount === 7) break;
		}
	}

	toggle(section: ViennaSection) {
		if (section.selected) {
			this.unselect(section)
		} else {
			this.checkSection({ section: section, showToast: true })
		}
	}

	unselect(section: ViennaSection): void {

		// Hmmm, I need to check if it's not a principal section first

		// console.log(`unselect()`)

		for (let selected of this.selectedSections) {

			if (!this.ms.display.modalPrincipalSections && selected.auxiliaryOf && selected.auxiliaryOf.includes(section.classification)) {
				if (!confirm(this.ms.translate("principal section remove warning").replace("%s", selected.name))) return;
			}

		}

		section.selected = false
		section.checked = false

		this.selectedSections = this.selectedSections.filter(s => s.classification !== section.classification)

		this.filterSections()
	}


}
