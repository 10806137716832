import { Component, OnInit } from '@angular/core';
import { MechanicsService } from '../_services/mechanics.service';
import { MainService } from '../_services/main.service';
import { ViennaSection } from '../../../../server/src/interfaces/ViennaSection.interface';

import { ClipboardService } from 'ngx-clipboard'

@Component({
	selector: 'buttons-export',
	templateUrl: './buttons-export.component.html',
	styleUrls: ['./buttons-export.component.css']
})
export class ButtonsExportComponent implements OnInit {

	constructor(public mas: MainService, public ms: MechanicsService, private _clipboardService: ClipboardService) { }

	ngOnInit() {
	}

	export() {

		// Column names 
		let csvContent: string
		if (this.ms.preferences.showAuxiliaryOf) {
			csvContent = `Classification, Description, Auxiliary of\r\n`;
		} else {
			csvContent = `Classification, Description\r\n`;
		}

		(<ViennaSection[]>this.mas.selectedSections).forEach(section => {

			// and NO, ES6's multiline strings don't work well with CSV export
			csvContent += `"${section.classification}"` // Leave it like this with surrounding quotes (allowing inner commas)
			csvContent += `,"${section.name}"`
			if (this.ms.preferences.showAuxiliaryOf) csvContent += `,"` + (section.auxiliaryOf ? section.auxiliaryOf.join(", ") : "-") + `"`
			csvContent += `\r\n`

		});

		const csvData = new Blob([csvContent], { type: 'text/csv' }),
			link = document.createElement("a");

		link.setAttribute("href", URL.createObjectURL(csvData))

		link.setAttribute("download", `${this.ms.currentFileName}_classification.csv`)
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file

	}

	unselect(): void {

		for (let section of this.ms.allSections) {
			section.selected = false
			section.checked = false
		}

		this.mas.selectedSections = []

		this.mas.filterSections()
	}

	localStorage() {

		let string: any = localStorage.getItem("classifSeries") || "{}",
			object = JSON.parse(string);

		object[this.ms.currentFileName] = (<ViennaSection[]>this.mas.selectedSections).map(section => ({
			c: section.classification,
			n: section.name,
			s: section.score
		}))

		localStorage.setItem("classifSeries", JSON.stringify(object))

		alert(`Content added to LocalStorage["classifSeries"].`)
	}

	toClipboard() {

		let arr: string[] = []

		for (let section of this.mas.selectedSections) {
			arr.push(`${section.classification} - ${section.name}`)
		}

		let text = arr.join("; ")

		this._clipboardService.copyFromContent(text)

		let msg = this.ms.translate("copied to clipboard")

		this.ms.toast({ msg, timeout: 2000 })
	}
}

