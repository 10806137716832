import { Component } from '@angular/core';
import { MainService } from '../_services/main.service';
import { MechanicsService } from '../_services/mechanics.service';
import { ViennaSection } from '../../../../server/src/interfaces/ViennaSection.interface';

@Component({
	selector: 'modal-principal-sections',
	templateUrl: './modal-principal-sections.component.html',
	styleUrls: ['./modal-principal-sections.component.css']
})
export class ModalPrincipalSectionsComponent {

	constructor(public mas: MainService,
		public ms: MechanicsService) { }

	
}
