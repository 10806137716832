import { Component } from '@angular/core';
import { MainService } from '../_services/main.service';
import { MechanicsService } from '../_services/mechanics.service';

const config = {
	resizeDroppedImage: false,
	maxSideLength: 224 // pixels. Ignored if resizeDroppedImage is set to false.
}

@Component({
	selector: 'imagedrop',
	templateUrl: './imagedrop.component.html',
	styleUrls: ['./imagedrop.component.css']
})
export class ImagedropComponent {

	isHovering: boolean = false;

	error: string = null

	base64: string = ""

	constructor(public mas: MainService,
		public ms: MechanicsService) { }

	clear() {
		this.mas.base64 = '';
		this.ms.layout = "centered"
		this.ms.currentFileName = ""
		this.clearSelected()

		if(this.ms.selectedCategory){
			this.ms.back()
		}
	}

	clearSelected() {
		for (let section of this.ms.allSections) section.selected = false
	}

	edit() {
		this.mas.editMode = true
		this.ms.layout = "centered"
	}

	preventDefaultAndStopPropagation($event) {
		$event.preventDefault();
		$event.stopPropagation();
	}

	onDragOver($event: any) {
		// console.log(`onDragOver()`);
		if (!this.isHovering) {
			this.isHovering = true;
		}
		this.preventDefaultAndStopPropagation($event);
		return false;
	};

	onDragLeave($event: any) {
		// console.log(`onDragLeave()`);
		this.isHovering = false
		this.preventDefaultAndStopPropagation($event);
		return false;
	}

	onDrop($event: any) {
		// console.log(`onDrop()`);
		this.preventDefaultAndStopPropagation($event);
		this.FileSelectHandler($event);
	}


	// file selection handler (can be called from drop, or from a file-requestor select box)
	public FileSelectHandler($event: any) {
		// console.log(`FileSelectHandler()`);
		this.isHovering = false
		var files = $event.target.files || $event.dataTransfer.files

		this.readFile(files[0])
	}

	readFile(file: File) {
		// console.log(`readFile()`, file);

		let ext = file.name.split(".").pop(),
			name = file.name;

		if (!["jpg", "jpeg", "png"].includes(ext.toLowerCase())) {
			setTimeout(() => this.error = "", 3000)
			return this.error = `Unsupported file type : ${ext}`
		}

		this.ms.currentFileName = name;

		var reader = new FileReader();

		reader.onload = e => {
			// console.log(`File reader output = `, reader.result)
			this.error = ""

			if (config.resizeDroppedImage) {
				// resizing image to 224x224 for the Vienna image classification API
				let image = new Image();
				image.onload = () => this.resizeImage(image)
				image.src = <string>reader.result
			} else {
				if (<string>reader.result === this.mas.base64){
					return // console.log(`Image is identical`)
				} 

				this.mas.base64 = <string>reader.result

				this.clearSelected()
				this.mas.sendImageData()
			}
		}

		reader.onerror = err => {
			this.error = reader.error.message
			setTimeout(() => this.error = "", 3000)
			reader.abort()
		}


		reader.readAsDataURL(file);
	}

	reset(){
		this.mas.base64 = '' + this.mas.originalBase64
		this.mas.originalBase64=''
		this.mas.sendImageData(false) // false = do not clear the selected sections list
	}


	resizeImage(image) {

		let canvas = document.createElement('canvas');

		canvas.width = canvas.height = 0 + config.maxSideLength

		// set size proportional to image
		if (image.width > image.height) {
			canvas.height = canvas.width * (image.height / image.width)
		} else {
			canvas.width = canvas.height * (image.height / image.width)
		}

		// console.log(`\nCanvas dimentions for resize : width = ${canvas.width}, height=${canvas.height}`);

		var ctx = canvas.getContext('2d');
		ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

		const resizedBase64: string = canvas.toDataURL()

		if (resizedBase64 === this.mas.base64){
			 return // console.log(`Image is identical`)
		}

		this.mas.base64 = resizedBase64

		this.clearSelected()
		this.mas.sendImageData()
	}

}
