import { Component } from '@angular/core';
import { MechanicsService } from '../_services/mechanics.service';
import { MainService } from '../_services/main.service';

@Component({
	selector: 'options',
	templateUrl: './options.component.html',
	styleUrls: ['./options.component.css']
})
export class OptionsComponent {

	public showOptionsMenu: boolean = false

	constructor(public ms: MechanicsService, public mas: MainService) { }

	closeMenu() {
		setTimeout(() => {
			this.showOptionsMenu = false
		})
	}
	
	saveSetting(value=null , which: string) {

		// console.log(`saveSetting() - value=${value}, which=${which}`);

		if(value){
			// console.log(`setting localStorage['${which}'] to `, value)
			localStorage.setItem(which, value)
			return 
		}

		if (this.ms[which]) {
			localStorage.setItem(which, "true")
		} else {
			localStorage.removeItem(which)
		}
	}
	


	async changeViennaVersion(){

		// Need to reload all Categories, Devisions and Sections in Vienna 9, rehydrate objects, rebuild tooltips, etc.
		await this.ms.buildStuff();
		this.mas.sendImageData(); // Resending the same image to the backend, specifying viennaVersion
		this.ms.savePreferences();
	}


}
