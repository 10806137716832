import { Component, OnInit } from '@angular/core';
import { MainService } from '../_services/main.service';
import { MechanicsService } from '../_services/mechanics.service';
import { ViennaSection } from '../../../../server/src/interfaces/ViennaSection.interface';

@Component({
	selector: 'quick-add',
	templateUrl: './quick-add.component.html',
	styleUrls: ['./quick-add.component.css']
})
export class QuickAddComponent implements OnInit {

	public inputValue: string = ""

	public suggestions: ViennaSection[] = []

	timeoutDebounce: any = null

	constructor(public mas: MainService, public ms: MechanicsService) { }

	ngOnInit() {
		window.addEventListener("added to selection", () => {
			// console.log(`Event triggered : "added to selection"`)
			this.filter(false)
		})
	}

	clear(caller = "unknown") {
		// console.log(`clear() quick-add input - caller = `, caller)
		setTimeout(() => {
			this.suggestions = []
			this.inputValue = ""
		});
	}

	onInput($event) {

		this.inputValue = $event.target.value

		if (!this.inputValue.length) {
			this.suggestions = []
			return
		}

		this.filter()
	}

	filter(debounce: boolean = true) {

		if (!this.inputValue.length) return

		// console.log(`filtering...`)

		this.timeoutDebounce = null

		let delay: number = debounce ? 300 : 0

		this.timeoutDebounce = setTimeout(() => {

			let selectedSectionsClassif = this.mas.selectedSections.map(s => s.classification)

			let filterWithoutDiaritics = this.inputValue.toLowerCase().trim()
				.replace(/[\u0300-\u036f]/g, "") // removing diacritics

			if ("".normalize) {
				filterWithoutDiaritics = filterWithoutDiaritics.normalize('NFD')
			}

			this.suggestions = this.ms.allSections
				.filter(section => section.nameLower.includes(filterWithoutDiaritics)
					&& !selectedSectionsClassif.includes(section.classification)
				)

			this.suggestions.sort((a, b) => a.score > b.score ? -1 : 1)

			this.suggestions = this.suggestions.slice(0, 10)

		}, delay)
	}

	select(section: ViennaSection) {
		this.mas.checkSection({ section: section, showToast: true })
		this.filter(false)
	}
}
