export const animalsSeriesMapping = {
	"I": [
		"3.1.1",
		"3.1.4",
		"3.1.6",
		"3.1.8",
		"3.1.14",
		"3.1.15",
		"3.1.16"
	],
	"II": [
		"3.2.1",
		"3.2.7",
		"3.2.9",
		"3.2.11",
		"3.2.13",
		"3.2.15"
	],
	"III": [
		"3.3.1",
		"3.3.2",
		"3.3.3",
		"3.3.15"
	],
	"IV": [
		"3.4.1",
		"3.4.7",
		"3.4.11",
		"3.4.12",
		"3.4.13",
		"3.4.18",
		"3.4.20"
	],
	"V": [
		"3.5.1",
		"3.5.3",
		"3.5.5",
		"3.5.6",
		"3.5.7",
		"3.5.9",
		"3.5.11",
		"3.5.15",
		"3.5.17",
		"3.5.19",
		"3.5.20"
	]
}