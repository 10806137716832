import { Component, OnInit } from '@angular/core';
import { MechanicsService } from '../_services/mechanics.service';

@Component({
  selector: 'modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.css']
})
export class ModalVideoComponent implements OnInit {

  constructor(public ms:MechanicsService) { }

  ngOnInit(): void {
  }

}
