

import { Component } from '@angular/core';
import { MechanicsService } from '../_services/mechanics.service';

@Component({
  selector: 'page-videos',
  templateUrl: './page-videos.component.html',
  styleUrls: ['./page-videos.component.css']
})
export class PageVideosComponent {

	videos : string[] = [
		"basics",
		"advanced",
		"quickAdd",
		"imageEditor"
	]

  constructor(public ms:MechanicsService) { }

  openVideoModal(video:string){

	this.ms.toggleModal("Video")
	this.ms.display["modalVideo"] = video
}

}
